@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins/Poppins-Regular.ttf') format('truetype');
}

.footer {
    width: auto;
    height: auto;
    background-color: #005275;
    font-family: 'Poppins';

    &__container {
        padding-top: 4em;
        height: inherit;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 3em;
    }

    &__section {
        display: grid;
        grid-template-columns: 2fr 2fr 2fr 2fr 1fr;
        justify-content: center;
    }

    &__row {
        display: flex;
        flex-direction: row;
        gap: 0.5em;
        justify-content: center;
        align-items: center;
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 2em;
        align-items: center;
    }

    &__link {
        color: #fff;
        font-weight: 600;
        font-size: 0.8em;
        @media screen and (min-width: 1000px) {
            font-size: 1em;
        }
    }

    &__text-1 {
        color: #fff;
        font-weight: 600;
        font-size: 1em;
        text-align: center;

        @media screen and (min-width: 1000px) {
            font-size: 1.2em;
        }
    }

    &__clickable {
        cursor: pointer;
    }

    &__text-2 {
        color: #fff;
        font-weight: 600;
        font-size: 0.8em;

        @media screen and (min-width: 1000px) {
            font-size: 1em;
        }
    }

    &__logo {
        width: 200px;
        height: auto;
        justify-self: center;

        @media screen and (min-width: 1000px) {
            width: 400px;
        }
    }

    &__social {
        height: auto;
        width: 25px;

        @media screen and (min-width: 800px) {
            width: 30px;
        }

        @media screen and (min-width: 1000px) {
            width: 30px;
        }

        @media screen and (min-width: 1300px) {
            width: 50px;
        }

        &:hover {
            cursor: pointer;
        }
    }

    &__app {
        height: auto;
        width: 50px;
        
        @media screen and (min-width: 800px) {
            width: 65px;
        }

        @media screen and (min-width: 1000px) {
            width: 80px;
        }

        @media screen and (min-width: 1300px) {
            width: auto;
        }
    }

    &__arrow {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: #EBEBEB;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            cursor: pointer;
            background-color: #fff;
        }

        @media screen and (min-width: 1000px) {
            width: 100px;
            height: 100px;
        }
    }

    &__arrow-up {
        width: auto;
        height: 43px;
        
        @media screen and (min-width: 1000px) {
            height: auto;
        }
    }
}