@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins/Poppins-Regular.ttf') format('truetype');
}

.skills-card {
  &__container {
    font-family: 'Poppins';
    border: 1px solid #000000;
    border-radius: 16px;
    padding: 0.5em;
    padding-right: 1em;

    &:hover {
      cursor: pointer;
      border-color: #3da1cc;
      color: #3da1cc;
    }

    &:active {
      background-color: rgba($color: #878787, $alpha: 0.2);
    }
  }
  
  &__container-removed {
    font-family: 'Poppins';
    border: 1px solid #000000;
    border-radius: 16px;
    padding: 0.5em;
    padding-right: 1em;
  
    &:hover {
      cursor: pointer;
      border-color: #ff0000;
      color: #ff0000;
    }
  
    &:active {
      background-color: rgba($color: #878787, $alpha: 0.2);
    }
  }

  &__container-selected {
    font-family: 'Poppins';
    border: 1px solid #3da1cc;
    border-radius: 16px;
    padding: 0.5em;
    padding-right: 1em;
    padding-left: 1em;
  }

  &__section {
    display: flex;
    flex-direction: row;
    gap: 0.5em;
  }
}
