@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins/Poppins-Regular.ttf') format('truetype');
}

.modal-linkedIn {
    font-family: 'Poppins';
    background-color: white;
    padding: 2em;
    width: fit-content;
    max-width: 90%;
    border-radius: 10px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    &__title {
        color: #474747;
        text-align: center;
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        padding-bottom: 1em;
    }
    
    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0.5em;
    }
    
    &__subtitle {
        color: #474747;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    &__button-section {
        margin-top: 1em;
        display: flex;
        flex-direction: row;
        gap: 2em;
        justify-content: center;
    }
}