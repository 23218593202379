@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins/Poppins-Regular.ttf') format('truetype');
}

.std-bord-container {
  display: flex;
  flex-direction: column;

  &__page {
    display: flex;
    flex-direction: row;
    height: inherit;
  }

  &__content {
    width: 100%;
    height: inherit;
    background-color: #f2f2f2;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 1300px) {
      align-items: center;
      width: fit-content;
    }
  }

  &__row {
    display: grid;
    grid-template-columns: 1fr 3fr;

    @media screen and (max-width: 1300px) {
      display: flex;
      flex-direction: column;
      // grid-template-columns: 1fr;
    }
  }

  &__skeleton {
    margin-top: 3.1em;
    margin-left: 3em;
  }

  &__skeleton-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1em;
    margin-bottom: 2em;
  }

  &__skeleton-text {
    display: flex;
    flex-direction: column;
  }


}
