@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins/Poppins-Regular.ttf') format('truetype');
  }

.std-dashboard-content {
    display: flex;
    flex-wrap: wrap;
}

h2 {
    margin: 0;
    padding: 0;
}

.std-dashboard-ca {
    padding: 1.8em 2.5em 1.8em 2.5em;
    margin: 3em 0 3em 5em;
    font-family: 'Poppins';
    background-color: white;
    //width: 40em;
    border: 0.01em solid white;
    border-radius: 2em;
    display: flex;
    flex-direction: column;
    gap: 1.8em;

    &__container {
        display: grid;
        gap: 2.5em;
        grid-template-columns: 1fr 1.5fr;
    }

    &__section {
        display: flex;
        flex-direction: column;
        gap: 3em;
    }

    &__sub-section {
        display: flex;
        flex-direction: column;
    }

    &__content {
        display: flex;
        flex-direction: row;
        gap: 0.8em;
    }

    &__text-1 {
        color: #4B4B4B;
        font-family: 'Poppins';
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    &__value {
        color: #4B4B4B;
        font-family: 'Poppins';
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    &__link {
        color: #005275;
        font-family: 'Poppins';
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        cursor: pointer;
    }

    &__logo {
        width: 40px;
    }
}

.std-dashboard-card {
    padding: 1.8em 2.5em 1.8em 2.5em;
    margin: 3em 0 0 5em;
    font-family: 'Poppins';
    background-color: white;
    max-width: 20em;
    border: 0.01em solid white;
    border-radius: 2em;
    display: flex;
    flex-direction: column;
    gap: 1.8em;

    &__title {
        font-size: 1.7em;
        color: #3DA1CC;
    }

    &__cursor {
        cursor: pointer;
    }

    &__content {
        font-size: 1.1em;
        border: 0.01em solid #000;
        border-radius: 1em;
        padding: 1em;
    }

    &__button {
        display: flex;
        justify-content: center;
    }

    &__object {
        font-size: 1.1em;
        display: flex;
        flex-direction: column;
        gap: 0.9em;
    }

    &__file {
        display: flex;
        align-items: center;
        gap: 1em;
    }

    &__notfilled {
        color: black;
    }

    &__pending {
        color: orange;
    }

    &__validated {
        color: #3DA1CC;
    }

    &__denied {
        color: red;
    }
}