@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins/Poppins-Regular.ttf') format('truetype');
  }

.base-button {
    background-image: linear-gradient(to right, #3DA1CC, #005275);
    color: #FFF;
    padding: 1em 4em 1em 4em;
    border: 1px solid transparent; 
    border-radius: 25px;
    font-size: 1em;
    font-weight: 600;
    font-family: 'Poppins';
    width: 17em;

    &__little {
        background-image: linear-gradient(to right, #3DA1CC, #005275);
        color: #FFF;
        padding: 0.5em 2em 0.5em 2em;
        border: 1px solid transparent; 
        border-radius: 25px;
        font-size: 0.8;
        font-weight: 600;
        font-family: 'Poppins';

        &:hover {
            color: #005275;
            background: linear-gradient(white, white) padding-box,
            linear-gradient(to right, #3DA1CC, #005275) border-box;
            cursor: pointer;
        }
    }

    &:hover {
        color: #005275;
        background: linear-gradient(white, white) padding-box,
        linear-gradient(to right, #3DA1CC, #005275) border-box;
        cursor: pointer;
    }
}

.classic-button {
    background-color: #005275;
    color: #FFF;
    padding: 0.5em 1em 0.5em 1em;
    border: 1px solid transparent; 
    border-radius: 10px;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Poppins';

    &--refuse {
        background-color: #FF0832;
    }

    &--cancelled {
        background-color: #B1B1B1;
    }

    &--disabled {
        opacity: 30%;
    }

    &:hover {
        cursor: pointer;
    }
}