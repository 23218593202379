@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins/Poppins-Regular.ttf') format('truetype');
}

.verify-page {
    font-family: 'Poppins';
    height: auto;

    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 5em;
    }
    
    &__section {
        height: 100vh;
    }

    &__text-section {
        display: flex;
        flex-direction: column;
        gap: 3em;
        max-width: 1200px;
    }

    &__title {
        font-size: 45px;
        color: #2A89B1;
        font-weight: 600;
        margin-bottom: 1em;
    }

    &__text {
        text-align: justify;
        color: #4B4B4B;
        font-weight: 600;
        font-size: 28px;
    }

    &__text-colored {
        text-align: justify;
        color: #2A89B1;
        font-weight: 600;
        font-size: 28px;
    }

    &__text-container {
        display: flex;
        flex-direction: row;
        gap: 0.5em;
    }

    &__button-container {
        position: relative;
        align-self: center;
        border-radius: 10px;
        border: 10px #fff solid;
        padding: 1em;
        padding-left: 2em;
        padding-right: 2em;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2.5em;
    }

    &__button-title {
        color: #005275;
        font-weight: 600;
        font-size: 28px;
    }

    &__logo {
        position: absolute;
        top: 5px;
        left: 10px;
    }
}