@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins/Poppins-Regular.ttf') format('truetype');
  }

body {
    margin: 0;
}

p {
    margin: 0;
}

.hotbar-container-2 {
    display: grid;
    grid-template-columns: 1fr 4fr 1.09fr;
    // display: flex;
    // justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 5.3em;
    background-color: #003751;
}   

.hotbar-container {
    display: grid;
    grid-template-columns: 1fr 4fr 1.09fr;
    // display: flex;
    // justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 5.3em;
    background-color: #005275;

    &__logo {
        width: 214px;
        height: auto;
    }

    &__title {
        text-align: center;
        font-size: 1.8em;
        font-weight: 600;
        font-family: 'Poppins';
        color: #FFF;

        @media screen and (min-width: 792px) {
            font-size: 2.5em;
        }
    }

    &__button {
        margin-right: 2em;
        font-size: 1.2em;
        padding: 0.2em 2.5em 0.2em 2.5em;
        border: none;
        border-radius: 10px;
        border-color: #005275;
        background-color: #FFF;
        color: #005275;
        font-weight: 700;

        &:hover {
            opacity: 0.5;
        }
    }

    &__info {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: #FFF;
        margin-right: 1em;
        background-color: #005275;
    }

    &__picture {
        height: 3.5em;
        width: 3.5em;
        border-radius: 50%;
    }
}