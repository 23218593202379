@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins/Poppins-Regular.ttf') format('truetype');
}

.mission-stats {
  margin: 2em;
  padding: 1em 1em 1em 1em;
  background-color: white;
  color: #4b4b4b;
  border-radius: 1em;
  font-family: 'poppins' !important;

  &__title {
    color: #4B4B4B;
    font-family: 'Poppins';
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  &__content {
    padding: 1em;
    display: flex;
    flex-direction: column;
    background-color: #E6E6E6;

    &--title {
      font-size: 2em;
      font-family: 'Poppins';
    }
  }

  &__text {
    font-family: 'Poppins';
    font-size: 16px;

    &--important {
      font-weight: 700;
      font-size: 20px;
    }
  }

  &__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: .5em;
    margin: 1em;
    margin-top: 1.5em;
    margin-bottom: 3em;
    font-family: 'poppins';
  }
}

.std-statistics {
  &__container {
    max-width: 60em;
    max-height: 60em;
  }
}
