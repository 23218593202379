@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins/Poppins-Regular.ttf') format('truetype');
}

.mention-legales {
    display: flex;
    flex-direction: column;
    // align-items: center;
    font-family: 'Poppins';
    margin-right: 5em;
    margin-left:  5em;

    &__section {
        display: flex;
        flex-direction: column;
        gap: 0em;
        margin-bottom: 2em;
    }

    &__title {
        align-self: center;
        margin-top: 1em;
        margin-bottom: 1em;
        color: #4b4b4b;
        font-size: 50px;
        font-weight: 600;
    }

    &__subtitle {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 0.3em;
    }

    &__text {
        color: #4b4b4b;
        font-size: 20px;
    }

    &__text-colored {
        color: #2A89B1;
        font-size: 20px;
        font-weight: 600;
    }

    &__duo-text {
        display: flex;
        flex-direction: row;
        gap: 5px;
    }
}