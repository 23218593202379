@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins/Poppins-Regular.ttf') format('truetype');
}

.std-member-grp {
    font-family: 'Poppins';

    &__section {
        padding: 1em;
        margin-left: 1em;
        display: flex;
        flex-direction: column;
        gap: 1em;
        align-items: flex-start;
    }

    &__name {
        color: #4B4B4B;
        font-size: 20px;
        font-weight: 600;
    }

    &__logo {
        height: 25px;
        cursor: pointer;
    }

    &__card {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        gap: 1em;
    }
}