@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins/Poppins-Regular.ttf') format('truetype');
}

.report-btn {
  font-family: 'Poppins';
  background-color: #fff;
  border: #737370 1px solid;
  border-radius: 50%;
  height: 2.5vw;
  width: 2.5vw;
  display: flex;
  justify-content: center;
  position: absolute;
  top: -10px;
  right: -20px;

  &:hover {
    filter: none;
    border: #F56476 1px solid;
  }

  &__img {
    position: absolute;
    top: 0px;
    fill: #F56476;
    filter: grayscale(100%);
    
    &:hover {
      filter: none;
    }
  }

  &__modal {
      font-family: 'Poppins';
      background-color: white;
      padding: 2em;
      width: fit-content;
      max-width: 90%;
      border-radius: 10px;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1000;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      display: flex;
      flex-direction: column;
  }

  &__title {
    font-family: 'Poppins';
    font-size: 2vw;
  }

  &__subtitle {
    margin-bottom: 0.5vw;
    color: #737370;
  }

  &__section {
    margin-top: 2vw;
  }

  &__button-section {
    margin-top: 2vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }

  &__input {
    width: 40vw;
  }

}