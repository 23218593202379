@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins/Poppins-Regular.ttf') format('truetype');
}

.std-invite-card {
    position: relative;
    padding: 2em;
    border: 1px solid #000;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    width: 30em;
    justify-content: space-between;
    align-items: center;
    margin-top: 1vw;

    @media screen and (min-width: 800px) and (max-width: 1000px) {
        width: 25em;
    }

    @media screen and (min-width: 645px) and (max-width: 800px) {
        width: 18em;
    }

    @media screen and (min-width: 100px) and (max-width: 645px) {
        width: 14em;
    }

    &__section {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1em;
    }

    &__name {
        color: #4B4B4B;
        font-family: Poppins;
        font-size: 20px;
        font-weight: 600;
    }
}