@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins/Poppins-Regular.ttf') format('truetype');
}

.homepage {
    background-color: #003751;
    font-family: 'Poppins';
    display: flex;
    flex-direction: column;
    align-items: center;

    &__picture {
        width: 100%;
        height: 36vw;
        object-fit: cover;
        filter: grayscale(30%);
    }

    &__text {
        font-size: 2vw;
        color: #fff;
        font-weight: 600;
        text-align: center;
        max-width: 80vw;
        margin-bottom: 15vw;
    }

    &__text-1 {
        margin-top: 15vw;
        font-size: 2vw;
        color: #fff;
        font-weight: 600;
        text-align: center;
        max-width: 80vw;
    }

    &__abs-logo {
        position: absolute;
        // top: clamp(200px, 200px, 200px);
        top: 15vw;
        left: 7vw;
        height: auto;
        width: 30vw;
    }

    &__phones {
        position: absolute;
        top: 25vw;
        left: 9vw;
        height: auto;
        width: 34vw;
    }

    &__laptop {
        position: absolute;
        top: 35vw;
        left: 65vw;
        height: auto;
        width: 25vw;
    }

    &__title-section {
        position: absolute;
        top: 12vw;
        left: 43vw;
        text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7);
    }

    &__title {
        font-size: 5vw;
        font-weight: 600;
        color: #fff;
    }

    &__title-colored {
        font-size: 5vw;
        font-weight: 600;
        color: #00C0FF;
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 2em;
    }

    &__subtext {
        color: #4B4B4B;
        font-weight: 600;
        font-size: 16px;
    }

    &__section {
        margin-top: 8vw;
        margin-bottom: 8vw;
        display: flex;
        flex-direction: column;
        gap: 4em;
    
        @media screen and (min-width: 1250px) {
            display: flex;
            flex-direction: row;
        }
    }

    &__button-section {
        display: flex;
        flex-direction: row;
        gap: 2em;
    }

    &__card-container {
        position: relative;
        background-color: #fff;
        align-self: center;
        border-radius: 10px;
        border: 10px #fff solid;
        padding: 1em;
        padding-left: 2em;
        padding-right: 2em;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2.5em;
        max-width: 550px;
        height: 300px;
    }

    &__content-title {
        color: #000;
        font-weight: 600;
        font-size: 28px;
    }

    &__content-title-colored {
        color: #005275;
        font-weight: 600;
        font-size: 28px;
    }

    &__logo {
        position: absolute;
        top: 5px;
        left: 45px;
        width: 80px;
        height: auto;
    }
}