@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins/Poppins-Regular.ttf') format('truetype');
}

.std-profile-content {
  margin-top: 3.1em;
  display: flex;
  padding: 1.8em 2em 1.8em 4em;
  background-color: white;
  font-family: 'Poppins';
  color: #4b4b4b;
  width: -webkit-fill-available;
  border-radius: 15px;

  &__avatar {
    border: 0.1em solid #3da1cc;
    width: 10em !important;
    height: 10em !important;

    @media screen and (min-width: 1000px) {
      width: 15em !important;
      height: 15em !important;
    }
  }

  &__stars-selected {
    color: yellow;
    margin-left: -0.7em;
  }

  &__stars {
    filter: grayscale(100%);
    margin-left: -0.7em;
  }

  &__circle {
    text-align: center;
    background-color: #4b4b4b;
    border: #4b4b4b solid 0.1em;
    border-radius: 100%;
    height: 0.4em;
    width: 0.4em;
  }

  &__edit {
    margin-top: 1em;
    height: 2em !important;
    width: 100% !important;
    cursor: pointer;
  }

  &__edit-small {
    margin-top: 1em !important;
    height: 1.5em !important;
    width: 100% !important;
    cursor: pointer;
  }

  &__container {
    display: flex;
    flex-direction: row;
    gap: 2em;
  }

  &__container-2 {
    display: flex;
    flex-direction: row;
  }

  &__row {
    display: grid;
    position: relative;
  }

  &__text {
    position: absolute;
    font-size: 0.6em;
    right: 0;
    top: 10px;
  }

  &__content {
    margin-left: 2em;
    display: flex;
    flex-direction: column;
    gap: 1em;
    font-size: 1.2em;
    max-width: 15em;

    @media screen and (min-width: 1000px) {
      max-width: 25em;
    }
  }

  &__avatar-section {
    flex: 1 !important;
    flex-shrink: 1 !important;
  }

  &__mark {
    margin-left: 0.7em;
    display: flex;
    flex-direction: row;
    gap: 1em;
    align-items: center;
  }

  &__section {
    display: flex;
    flex-direction: row;
    gap: 0.6em;
  }

  &__site {
    color: #3da1cc;
  }

  &__title {
    margin-bottom: -0.5em;
    display: flex;
    align-items: center;
    font-family: 'Poppins';
    text-transform: uppercase;
  }

  &__button--send {
    margin: 1em;
  }
}
