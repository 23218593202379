@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins/Poppins-Regular.ttf') format('truetype');
}

.std-group {
  margin: 2em 2em 2em 0em;
  font-family: 'Poppins';

  &__container {
    display: grid;
    grid-template-columns: 5fr 1.2fr;
  }

  &__message-header {
    display: flex;
    align-items: center;
    margin-bottom: 0.5em;
    justify-content: space-between; /* Ajouté pour aligner le nom et la date */
  }

  &__message-sender {
    font-weight: bold;
    font-size: 14px;
    /* Vous pouvez ajuster les marges si nécessaire */
  }

  &__message-timestamp {
    font-size: 12px;
    color: #888;
    margin-left: auto; /* Pour aligner la date à droite */
  }

  &__member-container {
    background-color: #fff;
    min-height: 91.5vh;
    margin-top: -96px;
  }

  &__button-container {
    display: flex;
    flex-direction: row-reverse;
    gap: 1em;
    margin-right: 4vw;
    height: fit-content;
  }

  &__member-title {
    padding-top: 1em;
    color: #4b4b4b;
    opacity: 30%;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
  }

  &__section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  &__picture {
    height: 120px;
    border-radius: 10%;
  }

  &__details {
    position: relative;
    display: flex;
    flex-direction: row;
    margin-top: 2vw;
    margin-right: 4vw;
    gap: 1em;
    background-color: #fff;
    border-radius: 15px;
    padding: 1vw;
  }

  &__details-section {
    display: flex;
    flex-direction: column;
  }

  &__image {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-top: 20vh;
    padding-bottom: 20vh;
  }

  &__text {
    color: #000;
    font-size: 1.25em;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  &__description {
    color: #4b4b4b;
    font-family: Poppins;
    opacity: 70%;
  }

  &__chat-messages {
    flex-grow: 1;
    overflow-y: auto;
    max-height: 600px;
    margin-top: 2em;
    margin-bottom: 2em;
    margin-right: 4em;
    padding: 0.5em;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  }

  &__message {
    margin-bottom: 1em;
  }

  &__message-header {
    display: flex;
    align-items: center;
    margin-bottom: 0.5em;
  }

  &__message-picture {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 0.5em;
  }

  &__message-sender {
    font-weight: bold;
    font-size: 14px;
  }

  &__message-text {
    background-color: #f1f1f1;
    padding: 0.75em;
    border-radius: 10px;
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  &__chat-input {
    display: flex;
    width: 95%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3em;
  }

  &__chat-input input {
    flex-grow: 1;
    margin-right: 1em;
    padding: 0.5em;
  }
}

.std-group-modal {
  font-family: 'Poppins';
  background-color: white;
  //padding: 2em;
  width: fit-content;
  margin-top: 35vh;
  //margin-left: 50vh;
  border-radius: 10px;

  @media screen and (min-width: 1700px) {
    margin-left: 50vh;
  }

  @media screen and (min-width: 1400px) and (max-width: 1700px) {
    margin-left: 40vh;
  }

  @media screen and (min-width: 1250px) and (max-width: 1400px) {
    margin-left: 25vh;
  }

  @media screen and (min-width: 1000px) and (max-width: 1250px) {
    margin-left: 20vh;
    width: 50em;
  }

  @media screen and (min-width: 800px) and (max-width: 1000px) {
    margin-left: 10vh;
    width: 40em;
  }

  @media screen and (min-width: 645px) and (max-width: 800px) {
    margin-left: 10vh;
    width: 30em;
  }

  @media screen and (min-width: 557px) and (max-width: 645px) {
    margin-left: 8vh;
    width: 30em;
  }

  @media screen and (min-width: 300px) and (max-width: 557px) {
    margin-left: 1vh;
    width: 30em;
  }

  &__title-section {
    display: flex;
    justify-content: center;
    padding: 2em 2em 1em 2em;
    border-bottom: #d9d9d9 1px solid;
  }

  &__title {
    color: #000;
    font-family: Poppins;
    font-size: 2em;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  &__content-section {
    padding: 1em 2em 2em 2em;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__textfield {
    border-radius: 10px;
    padding: 1em;
    margin-top: 2em;
    font-family: 'Poppins';
  }

  &__button {
    margin-top: 2em;
    text-align: center;
  }
}

.std-invite-modal {
  font-family: 'Poppins';
  background-color: white;
  width: fit-content;
  min-width: 40em;
  margin-top: 30vh;
  border-radius: 10px;

  @media screen and (min-width: 1700px) {
    margin-left: 60vh;
  }

  @media screen and (min-width: 1400px) and (max-width: 1700px) {
    margin-left: 52vh;
  }

  @media screen and (min-width: 1250px) and (max-width: 1400px) {
    margin-left: 35vh;
    min-width: 40em;
  }

  @media screen and (min-width: 1000px) and (max-width: 1250px) {
    margin-left: 20vh;
    min-width: 40em;
  }

  @media screen and (min-width: 800px) and (max-width: 1000px) {
    margin-left: 18vh;
    min-width: 40em;
  }

  @media screen and (min-width: 645px) and (max-width: 800px) {
    margin-left: 15vh;
    min-width: 28em;
  }

  @media screen and (min-width: 557px) and (max-width: 645px) {
    margin-left: 8vh;
    min-width: 20em;
  }

  @media screen and (min-width: 300px) and (max-width: 557px) {
    margin-left: 1vh;
    min-width: 20em;
  }

  &__title-section {
    display: flex;
    justify-content: center;
    padding: 2em 2em 1em 2em;
    border-bottom: #d9d9d9 1px solid;
  }

  &__title {
    color: #000;
    font-family: Poppins;
    font-size: 2em;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  &__content-section {
    padding: 1em 2em 2em 2em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: auto;
  }

  &__searchbar-section {
    display: flex;
    flex-direction: column;
    width: auto;
  }

  &__searchbar-container {
    position: relative;
    display: grid;
  }

  &__search-icon {
    position: absolute;
    top: 4px;
    left: 5px;
    width: 28px;
    cursor: pointer;
  }

  &__searchbar {
    border-radius: 20px;
    padding-left: 2.5em;
    height: 30px;
    width: auto;
  }

  &__list {
    padding-left: 0 !important;
    display: flex;
    flex-direction: column;
    gap: 2em;
  }

  &__button {
    margin-top: 2em;
    text-align: center;
  }
}

.std-exclude-modal {
  font-family: 'Poppins';
  background-color: white;
  padding: 2em;
  width: fit-content;
  max-width: 90%;
  border-radius: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &__title-section {
    display: flex;
    justify-content: center;
    padding: 2em 2em 1em 2em;
    border-bottom: #d9d9d9 1px solid;
  }

  &__close-icon {
    position: absolute;
    top: 100;
    left: 90%;
    cursor: pointer;
  }

  &__title {
    color: #000;
    font-family: Poppins;
    font-size: 2em;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  &__content-section {
    padding: 1em 2em 2em 2em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: auto;
  }

  &__searchbar-section {
    display: flex;
    flex-direction: column;
    width: auto;
  }

  &__searchbar-container {
    position: relative;
    display: grid;
  }

  &__search-icon {
    position: absolute;
    top: 4px;
    left: 5px;
    width: 28px;
    cursor: pointer;
  }

  &__searchbar {
    border-radius: 20px;
    padding-left: 2.5em;
    height: 30px;
    width: auto;
  }

  &__list {
    padding-left: 0 !important;
    display: flex;
    flex-direction: column;
    gap: 2em;
  }

  &__button {
    margin-top: 2em;
    text-align: center;
  }
}
