@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins/Poppins-Regular.ttf') format('truetype');
}

.std-profile-exp {
  margin: 2em;
  padding: 1em 1em 1em 1em;
  background-color: white;
  min-width: 300px;
  color: #4b4b4b;
  border-radius: 1em;
  font-family: 'poppins';

  &__title-container {
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 1300px) {
      justify-content: center;
    }
  }
 
  &__content {
    display: flex;
    flex-direction: column;
    gap: 1em;
  }

  &__text {
    text-align: start;
  }

  &__button {
    text-align: center;
  }

  &__container {
    // margin: 0 1.5em 0 1.5em;
    display: flex;
    flex-direction: column;
    width: fit-content;
    position: relative;
  }

  &__section {
    display: flex;
    flex-direction: column;
    font-size: 1.5em;
    font-weight: 700;
    align-items: center;
  }

  &__img {
    width: 2.8em;
  }

  &__row {
    display: grid;
    grid-template-columns: 1fr 4fr;
    gap: 2em;
    margin: 0 1em 1em 1em;
    padding: 2em 0 2em 0;
  }

  &__separator {
    display: grid;
    grid-template-columns: 1fr 4fr;
    margin: 0 1em 1em 1em;
    padding: 2em 0 2em 0;
    gap: 2em;
    border-bottom: solid 1px #00000040;
  }

  &__columns {
    display: flex;
    flex-direction: column;
  }

  &__input {
    width: 100%;
  }

  &__datepicker {
    width: 100%;
    height: 2em;
  }

  &__button {
    width: 100%;
  }

  &__add {
    display: flex;
    flex-direction: row;
    gap: 1em;
    align-items: center;
    margin: 2em;
    padding: 1em;
    padding-right: 30em;
    border: 0.4em #4b4b4b dashed;
    width: fit-content;

    @media screen and (min-width: 1600px) {
      padding-right: 70em;
    }

    @media screen and (min-width: 1500px) and (max-width: 1600px) {
      padding-right: 60em;
    }

    @media screen and (min-width: 1350px) and (max-width: 1500px) {
      padding-right: 50em;
    }

    @media screen and (min-width: 1200px) and (max-width: 1350px) {
      padding-right: 45em;
    }
  }

  &__title {
    display: flex;
    margin: 0 0.5em 1em 0.5em;
  }

  &__edit {
    margin-top: 0.3em !important;
    height: 1.5em !important;
    width: 100% !important;
    cursor: pointer;
  }

  &__logo {
    height: 10em;
    max-width: 15em;
  }

  &__modal {
    font-family: 'poppins';
    background-color: white;
    padding: 3em;
    text-align: center;
    border-radius: 10px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__container-title {
    font-size: 2em;
    font-weight: 700;
    text-transform: uppercase;
  }

  &__position {
    font-size: 1.3em;
    font-weight: 700;
  }

  &__location {
    display: flex;
    flex-direction: row;
    gap: 0.5em;
  }

  &__calendar {
    display: flex;
    flex-direction: row;
    gap: 0.5em;
  }

  &__date {
    display: flex;
    flex-direction: row;
    gap: 1.8em;
    font-size: 0.8em;
    font-weight: 700;
    margin-bottom: 2em;
  }

  &__delete-skill {
    position: absolute;
    height: 35px;
    top: -20px;
    right: -60px;

    &:hover {
      cursor: pointer;
    }
  }
}

.react-datepicker-wrapper {
  display: inline !important;
}
