@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins/Poppins-Regular.ttf') format('truetype');
}

.std-profile-comp {
  margin: 2em;
  padding: 1em 1em 1em 1em;
  background-color: white;
  width: fit-content;
  color: #4b4b4b;
  border-radius: 1em;
  font-family: 'poppins';
  display: flex;
  flex-direction: row;
  position: relative;

  &__title-container {
    display: flex;
    flex-direction: row;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
  }

  &__container {
    margin: 0 1.5em 0 1.5em;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1em;
  }

  &__section {
    position: relative;
    display: flex;
    flex-direction: column;
    font-size: 1.5em;
    font-weight: 700;
    align-items: center;
  }

  &__img {
    width: 2.8em;
  }

  &__columns {
    display: flex;
    flex-direction: column;
  }

  &__add {
    display: flex;
    flex-direction: row;
    gap: 1em;
    align-items: center;
    margin: 2em;
    padding: 1em;
    border: 0.4em #4b4b4b dashed;
    width: fit-content;
  }

  &__title {
    display: flex;
    margin: 0 0.5em 1em 0.5em;
  }

  &__edit {
    margin-top: 0.2em !important;
    height: 2em;
    width: 2em;
    cursor: pointer;
  }

  &__modal {
    font-family: 'Poppins';
    background-color: white;
    position: fixed;
    border-radius: 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 2em;
    text-align: center;
  }

  &__modal-title {
    font-size: 32px;
    color: #4b4b4b;
    padding-bottom: 1em;
  }

  &__textfield {
    margin-bottom: 1em;
  }

  &__filename {
    display: flex;
    flex-direction: row;
    gap: 1em;
    align-items: center;
  }

  &__img {
    width: 2em;
  }

  &__delete-skill {
    position: absolute;
    height: 25px;
    top: -10px;
    right: 0;
  }

  &__sep {
    font-size: 1.3em;
  }

  &__skill-list {
    display: flex;
    flex-direction: column;
    gap: 2em;
  }

  &__skills {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1em;
  }

  &__skills-title {
    color: #005275;
    font-size: 20px;
    font-family: 'Poppins';
    font-weight: 600;
  }

  &__skills-section {
    display: flex;
    flex-direction: column;
    gap: 1em;
  }
}
