@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins/Poppins-Regular.ttf') format('truetype');
  }

.std-document-content {
    display: flex;
    justify-content: center;
    margin: 2em;
}

h2 {
    margin: 0;
    padding: 0;
}

.std-document-card {
    padding: 1.8em 2.5em 1.8em 2.5em;
    margin: 3em 0 0 0;
    font-family: 'Poppins';
    background-color: white;
    //max-width: 20em;
    border: 0.01em solid white;
    border-radius: 2em;
    display: flex;
    flex-direction: column;
    gap: 1.8em;

    &__title {
        display: flex;
        font-size: 1.7em;
        color: #3DA1CC;
        justify-content: center;
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 2em;
        font-size: 1.1em;
        padding: 1em;
    }

    &__dropzone {
        display: flex;
        flex-direction: column;
    }

    &__dropzone-filename {
        font-size: 0.8em;
    }

    &__textfield-container {
        display: flex;
        flex-direction: column;
    }

    &__line {
        display: grid;
        grid-template-columns: repeat(2, 1fr) repeat(1, 0.5fr);
    }

    &__button {
        grid-column-start: 3;
        justify-content: center;
    }

    &__button {
        display: flex;
        justify-content: center;
    }

    &__object {
        font-size: 1.1em;
        display: flex;
        flex-direction: column;
        gap: 0.9em;
    }

    &__file {
        display: flex;
        align-items: center;
        gap: 1em;
    }

    &__notfilled {
        color: black;
    }

    &__pending {
        color: orange;
    }

    &__validated {
        color: #3DA1CC;
    }

    &__denied {
        color: red;
    }

    &__button {
        align-items: center;
    }
}

.std-documentV2 {
    display: flex;
    justify-content: center;
    font-family: 'Poppins';

    &__top-section {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        margin: 7vw 0 7vw 0;
        border-radius: 5px;
        width: auto;
        height: fit-content;
    }

    &__section {
        display: flex;
        flex-direction: column;
    }

    &__container {
        display: flex;
        flex-direction: row;
    }

    &__title {
        color: #3DA1CC;
        font-size: 2vw;
        font-weight: bold;
        align-self: center;
        margin-top: 1vw;
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 1vw;
        margin: 2vw 2vw 1vw 2vw;
        align-items: center;

        &--text-section {
            display: flex;
            flex-direction: row;
            gap: 6px;
            justify-content: center;
            align-self: center;
        }

        &--text {
            font-size: 1.1vw;
            font-weight: bold;
        }

        &--text-sp {
            font-size: 1.1vw;
            font-weight: bold;
            color: #3DA1CC;
        }
    }

    &__button {
        align-self: center;
        margin-top: 1vw;
        margin-bottom: 2vw;
    }

    &__status-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 6px;
    }
}