@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins/Poppins-Regular.ttf') format('truetype');
}

.notif-button {
    left: 50%;
    z-index: 9999;
    font-family: 'Poppins';

    @media screen and (min-width: 375px) {
        left: 61.5%;
    }

    @media screen and (min-width: 1600px) {
        left: 77%;
    }

    position: absolute;
    background-color: #fff;
    padding: 0.5em 0.6em 0.5em 0.6em;
    border-radius: 50%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);

    &:hover {
        cursor: pointer;
    }

    &__img {
        width: clamp(20px, 2vw, 25px);
        height: auto;
    }

    &__img2 {
        width: 250px;
        height: auto;
        opacity: 10%;
    }

    &__container {
        position: absolute;
        display: flex;
        flex-direction: column;
        background-color: #fff;
        width: 200px;
        height: 379px;
        @media screen and (min-width: 500px) {
            width: 352px;
            height: 379px;
        }
        flex-shrink: 0;
        top: 50px;
        border-radius: 10px;
        border: 1px solid rgba(75, 75, 75, 0.50);
        background: #FFF;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }

    &__container-2 {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        width: 200px;
        height: 379px;
        @media screen and (min-width: 500px) {
            width: 352px;
            height: 379px;
        }
        flex-shrink: 0;
        top: 50px;
        border-radius: 10px;
        border: 1px solid rgba(75, 75, 75, 0.50);
        background: #FFF;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }

    &__new-notif {
        position: absolute;
        top: -2px;
        left: 34px;
    }

    &__bg {
        width: 20px;
        height: auto;
    }

    &__count {
        font-family: 'Poppins';
        color: #fff;
        font-size: 14px;
        font-weight: 800;
        position: absolute;
        top: 49%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 9999;
    }

    &__email {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 1em 1em 0 1em;
    }
}