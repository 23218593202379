@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins/Poppins-Regular.ttf') format('truetype');
}

.notif-card {
    position: relative;
    padding: 1em;
    border-bottom: 1px solid #D9D9D9;
    font-family: 'Poppins';
    color: #4B4B4B;
    // display: flex;
    // flex-direction: row;
    display: grid;
    grid-template-columns: 4fr 1fr;

    align-items: center;
    gap: 1em;

    &__title {
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        font-size: 16px;
        font-weight: 600;
        color: #005275;
    }

    &__text {
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        font-size: 14px;
        font-weight: 600;
    }
    &__date {
        padding-top: 0.5em;
        font-size: 10px;
        color: #005275;
        font-weight: 600;
    }

    &__close {
        position: absolute;
        top: 10px;
        right: 10px;
    }

    &__new {
        position: absolute;
        top: 2px;
        left: 5px;
    }

    &__section {
        flex: 1;
    }

    &__section-2 {
        // flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__img {
        width: 40px;
        height: auto;
    }
}